<template>
  <div id="offer-settings">
    <h4>{{ $t('General') }}</h4>
    <b-card>
      <b-form-group>
        <b-form-checkbox
          v-model="allowSystemAgreementInCart"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('isSystemOfferAgreement') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group class="mb-0">
        <b-form-checkbox
          v-model="allowAgreementInCart"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('isOfferArrangement') }}
        </b-form-checkbox>
      </b-form-group>
    </b-card>
    <h4>{{ $t('Orders') }}</h4>
    <b-card>
      <b-form-group>
        <b-form-checkbox
          v-model="hideOrderProcessInCart"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('HideOrderProcess') }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group class="mb-0">
        <b-form-checkbox
          v-model="defaultCartOrderProcess"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('SetOrderAsDefault') }}
        </b-form-checkbox>
      </b-form-group>
    </b-card>
    <h4>{{ $t('Offers') }}</h4>
    <b-card>

      <b-form-group>
        <template #label>
          {{ $t('Patter') }}
          <b-badge id="short-modal"
                   class="cursor-pointer"
                   variant="success"
          >
            <sw-icon icon="CopyIcon" />
          </b-badge>
        </template>
        <b-form-input
          id="contactThreadCartOrderNumberPattern"
          v-model="contactThreadCartOrderNumberPattern"
        />
      </b-form-group>

      <b-form-group :label="$t('StartOrdinalNumber')">
        <b-form-input
          id="contactThreadCartOrderNumberPattern"
          v-model="contactThreadCartOrderNumberOrdinalNumberNext"
          type="number"
          min="1"
        />
      </b-form-group>

      <sw-select :name="$t('RestartOrdinalNumberType')">
        <v-select v-model="contactThreadCartOrderNumberOrdinalNumberType"
                  :options="odrinalNumberRestartTypes"
                  :reduce="e => e.value"
        >
          <template #option="{ label }">
            {{ $t(`${label}`) }}
          </template>
          <template #selected-option="{ label }">
            {{ $t(`${label}`) }}
          </template>
        </v-select>
      </sw-select>

      <b-popover target="short-modal"
                 container="offer-settings"
      >
        <vue-perfect-scrollbar style="max-height: 350px">
          <b-list-group flush>
            <b-list-group-item v-for="a in (new Array(6).fill(null).map((e, i) => i + 1))"
                               :key="a"
                               class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{ORDINAL_NUMBER:${a}}`)"
            >
              <div>{{ $tc('OrdinalNumber', a, { length: a }) }}</div>
            </b-list-group-item>

            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_MONTHLY}`)"
            >
              <div>{{ $tc('Months', 0) }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_MONTHLY_SHORT}`)"
            >
              <div>{{ $tc('MonthShort') }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_DAY}`)"
            >
              <div>{{ $tc('Days', 1) }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_DAY_SHORT}`)"
            >
              <div>{{ $tc('DayShort') }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_YEAR}`)"
            >
              <div>{{ $tc('Years', 1) }}</div>
            </b-list-group-item>
            <b-list-group-item class="cursor-pointer d-flex justify-content-between"
                               @click="copyValue(`{CREATE_YEAR_SHORT}`)"
            >
              <div>{{ $tc('YearShort') }}</div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
      </b-popover>

      <b-form-group
        :label="$t('MaxOffers')"
      >
        <b-form-input
          id="contactThreadOffersLimit"
          v-model="system.contactThreadOffersLimit"
          type="number"
        />
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          v-model="hideOfferProcessInCart"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100"
        >
          {{ $t('HideOfferProcess') }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group class="mb-0">
        <b-form-checkbox
          v-model="allowEditingOfferEmail"
          checked="true"
          name="check-button"
          variant="primary"
          switch
          inline
          class="w-100 py-25"
        >
          {{ $t('AllowEditingOfferEmail') }}
        </b-form-checkbox>
      </b-form-group>

      <b-overlay
        no-wrap
        :show="loading"
        spinner-variant="primary"
      />
    </b-card>

    <offer-email
      :loading="loading"
      @save="save"
    />
  </div>
</template>

<script>
import OfferEmail from '@/views/core/admin/templatesTabs/OfferEmail.vue'
import {
  GET_PRIVATE_SETTINGS, GET_SETTINGS, SAVE_OFFER_EMAIL, SAVE_SETTINGS,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import { BPopover } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
  name: 'OfferSettings',
  components: {
    OfferEmail, BPopover, VuePerfectScrollbar, vSelect,
  },
  data: () => ({
    loading: false,
    allowAgreementInCart: false,
    allowSystemAgreementInCart: false,
    allowEditingOfferEmail: false,
    defaultCartOrderProcess: false,
    hideOrderProcessInCart: false,
    hideOfferProcessInCart: false,

    contactThreadCartOrderNumberPattern: '',
    contactThreadCartOrderNumberOrdinalNumberNext: 0,
    contactThreadCartOrderNumberOrdinalNumberType: 'NEVER_RESET',

    odrinalNumberRestartTypes: [
      { value: 'NEVER_RESET', label: 'NeverReset' },
      { value: 'RESET_EVERY_YEAR', label: 'ResetEveryYear' },
      { value: 'RESET_EVERY_MONTH', label: 'ResetEveryMonth' },
    ],
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  watch: {
    hideOrderProcessInCart(newValue) {
      if (newValue) this.hideOfferProcessInCart = false
    },
    hideOfferProcessInCart(newValue) {
      if (newValue) {
        this.hideOrderProcessInCart = false
        this.defaultCartOrderProcess = true
      }
    },
  },
  async mounted() {
    this.loading = true
    try {
      const resp = await this.$store.dispatch(`system/${GET_SETTINGS}`)
      const privateResp = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
      this.allowSystemAgreementInCart = resp.allowSystemAgreementInCart || false
      this.allowAgreementInCart = resp.allowAgreementInCart || false
      this.allowEditingOfferEmail = resp.allowEditingOfferEmail || false
      this.defaultCartOrderProcess = resp.defaultCartOrderProcess || false
      this.hideOrderProcessInCart = resp.hideOrderProcessInCart || false
      this.hideOfferProcessInCart = resp.hideOfferProcessInCart || false
      this.contactThreadCartOrderNumberPattern = privateResp?.data?.item?.contactThreadCartOrderNumberPattern || ''
      this.contactThreadCartOrderNumberOrdinalNumberNext = privateResp?.data?.item?.contactThreadCartOrderNumberOrdinalNumberNext || 0
      this.contactThreadCartOrderNumberOrdinalNumberType = privateResp?.data?.item?.contactThreadCartOrderNumberOrdinalNumberType || 'NEVER_RESET'
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async save(translations) {
      this.loading = true
      this.system.allowSystemAgreementInCart = this.allowSystemAgreementInCart
      this.system.allowAgreementInCart = this.allowAgreementInCart
      this.system.allowEditingOfferEmail = this.allowEditingOfferEmail
      this.system.defaultCartOrderProcess = this.defaultCartOrderProcess
      this.system.hideOrderProcessInCart = this.hideOrderProcessInCart
      this.system.hideOfferProcessInCart = this.hideOfferProcessInCart
      this.system.contactThreadCartOrderNumberPattern = this.contactThreadCartOrderNumberPattern
      this.system.contactThreadCartOrderNumberOrdinalNumberNext = String(this.contactThreadCartOrderNumberOrdinalNumberNext || 0)
      this.system.contactThreadCartOrderNumberOrdinalNumberType = this.contactThreadCartOrderNumberOrdinalNumberType

      try {
        await Promise.all([this.saveTemplate(translations), this.saveSettings()])
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
    saveSettings() {
      return this.$store.dispatch(`system/${SAVE_SETTINGS}`, {})
    },
    saveTemplate(payload) {
      return this.$store.dispatch(`templates/${SAVE_OFFER_EMAIL}`, payload)
    },
    copyValue(value) {
      this.$copyText(value, this.$el).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
        this.$root.$emit('bv::hide::popover')
      }, e => {
        this.showToast('success', e)
      })
    },
  },
}
</script>

<style lang="scss">
#offer-settings {
    .popover {
        min-width: 280px;
        max-width: 350px !important;
        .popover-body {
            padding: 0.65rem 0 !important;
        }
    }
}
</style>
